<template>
    <BT-Blade-Items
        addBladeName="journey"
        archiveBladeName="journey-archives"
        bladeName="journeys"
        :bladesData="bladesData"
        canAdd
        canDelete
        canSearchLocal
        clearBlades
        :headers="[
            { text: 'Name', value: 'journeyName', title: 1, searchable: true },
            { text: 'Due Start On', value: 'dueStartOn', textFilter: 'toShortDate' },
            { text: 'Status', value: 'status', display: true }]"
        navigation="journeys"
        :onSelect="onSelect"
        :params="{ departureLocationID: locationID }"
        useServerPagination
        title="Journeys"
        :defaultBladeWidth="300">
        <template v-slot:settings>
            <v-list-item dense :to="{ name: 'template-optimization-hub' }">
                <v-list-item-icon>
                    <v-icon small>mdi-image-area</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-subtitle>Coverage Board</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </template>
        <template v-slot:listItem="{ item }">
            <v-list-item-content>
                <v-list-item-title>{{ item.journeyName }}</v-list-item-title>
                <v-list-item-subtitle>
                    <v-row class="mx-0 my-0 px-1 py-0">
                        <span>{{ item.dueStartOn | toDayShortDate }}</span>
                        <v-spacer />
                        <span v-if="item.endedOn != null">Completed</span>
                        <span v-else-if="item.startedOn != null">Departed</span>
                        <span v-else-if="item.isReady">Ready</span>
                        <span v-else class="text-right">Not Ready Yet</span>
                    </v-row>
                </v-list-item-subtitle>
            </v-list-item-content>
        </template>
        <template v-slot:status="{ item }">
            <span v-if="item.endedOn != null">Completed</span>
            <span v-else-if="item.startedOn != null">Departed</span>
            <span v-else-if="item.isReady">Ready</span>
            <span v-else>Not Ready Yet</span>
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Journeys',
    props: {
        bladesData: {
            type: Object,
            default: null
        },
        locationID: null,
        onSelect: {
            type: Function,
            default: null
        }
    }
}
</script>