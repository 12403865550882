var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blade-Items',{attrs:{"addBladeName":"journey","archiveBladeName":"journey-archives","bladeName":"journeys","bladesData":_vm.bladesData,"canAdd":"","canDelete":"","canSearchLocal":"","clearBlades":"","headers":[
        { text: 'Name', value: 'journeyName', title: 1, searchable: true },
        { text: 'Due Start On', value: 'dueStartOn', textFilter: 'toShortDate' },
        { text: 'Status', value: 'status', display: true }],"navigation":"journeys","onSelect":_vm.onSelect,"params":{ departureLocationID: _vm.locationID },"useServerPagination":"","title":"Journeys","defaultBladeWidth":300},scopedSlots:_vm._u([{key:"settings",fn:function(){return [_c('v-list-item',{attrs:{"dense":"","to":{ name: 'template-optimization-hub' }}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-image-area")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Coverage Board")])],1)],1)]},proxy:true},{key:"listItem",fn:function(ref){
        var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.journeyName))]),_c('v-list-item-subtitle',[_c('v-row',{staticClass:"mx-0 my-0 px-1 py-0"},[_c('span',[_vm._v(_vm._s(_vm._f("toDayShortDate")(item.dueStartOn)))]),_c('v-spacer'),(item.endedOn != null)?_c('span',[_vm._v("Completed")]):(item.startedOn != null)?_c('span',[_vm._v("Departed")]):(item.isReady)?_c('span',[_vm._v("Ready")]):_c('span',{staticClass:"text-right"},[_vm._v("Not Ready Yet")])],1)],1)],1)]}},{key:"status",fn:function(ref){
        var item = ref.item;
return [(item.endedOn != null)?_c('span',[_vm._v("Completed")]):(item.startedOn != null)?_c('span',[_vm._v("Departed")]):(item.isReady)?_c('span',[_vm._v("Ready")]):_c('span',[_vm._v("Not Ready Yet")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }